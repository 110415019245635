import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import CarList from "../../components/CarList";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

const CarListingPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>Find Self-drive Rental Cars In Goa</title>
        <meta name="description" content="Rent self-drive cars in Goa with a range of vehicles from economy to luxury. Enjoy affordable rates, easy booking, and reliable service for a seamless travel experience." />
        <link rel="canonical" href="https://viegascars.com/car-listing" />
      </Helmet>
      <Header />
      {/* <PageTitle
        pageTitle={t("header-navigation.car_listing")}
        pagesub={t("header-navigation.car_listing")}
      /> */}
      <CarList />
      <Footer />
    </Fragment>
  );
};
export default CarListingPage;
