import React, { Fragment } from "react";
import Header from "../../components/header";
import Hero from "../../components/hero";
import FindCar from "../../components/findcar";
import About from "../../components/About";
import Service from "../../components/Service";
import Promo from "../../components/Promo";
import HotOffers from "../../components/HotOffers";
import Testimonial from "../../components/Testimonial";
// import Team from "../../components/Team";
// import Help from "../../components/Help";
// import Blog from "../../components/Blog";
import Footer from "../../components/Footer";
import HelmetExport, { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    // <HelmetExport>
    <Fragment>
      <Helmet>
      <meta charSet="utf-8" />
        <title>Rent a Car in Goa | Top-notch Self-drive & Rental Cars to Explore Goa</title>
        <meta name="description" content="Goa’s best self-drive car rentals at your service. Choose from a wide range of cars for your travels. Call +91 98501 14297 to book now." />
        <link rel="canonical" href="https://viegascars.com" />
      </Helmet>
      <Header />
      <Hero />
      <FindCar />
      <About />
      <Service />
      <Promo />
      <HotOffers />
      <Testimonial />
      {/* <Team /> */}
      {/* <Help /> */}
      {/* <Blog /> */}
      <Footer />
    </Fragment>
    // </HelmetExport>
  );
};
export default HomePage;
