import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Container, Row, Col, Tabs, Tab, Modal, Button, Carousel} from "react-bootstrap";
import { FaCar, FaCogs, FaTachometerAlt } from "react-icons/fa";
import logo from "../../img/footer-logo.png";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TimePicker from "react-time-picker";
import img1 from "../../img/carLogos/Toyota.png";
import img2 from "../../img/carLogos/maruti-suzuki.png";
import img3 from "../../img/carLogos/renault.png";
import img4 from "../../img/carLogos/Mahindra.png";
import img5 from "../../img/carLogos/kia.png";
import img6 from "../../img/carLogos/Hyundai.png";


import Fortuner from "../../img/ToyotaUrbanCruiser auto/Fortuner.jpg"
import FortunerA1 from "../../img/ToyotaUrbanCruiser auto/Fortuner2.jpg"
import FortunerA2 from "../../img/ToyotaUrbanCruiser auto/Fortuner3.jpg"

import InnovaM from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-1.jpg"
import InnovaM1 from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-2.jpg"
import InnovaM2 from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-3.jpg"

import Swift3rdA from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-1.jpg"
import Swift3rdG1A from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-2.jpg"
import Swift3rdG2A from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-3.jpg"

// import Baleno from "../../img/swift/Baleno.png";
import Baleno from "../../img/Maruti Suzuki Baleno(Manual)/jpg/maruti-suzuki-baleno-manual-self-drive-1.jpg"
import Baleno1 from "../../img/Maruti Suzuki Baleno(Manual)/jpg/maruti-suzuki-baleno-manual-self-drive-2.jpg"

import MarutiJimnyA from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-1.jpg"
import MarutiJimnyA1 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-2.jpg"
import MarutiJimnyA2 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-3.jpg"
import MarutiJimnyA3 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-4.jpg"



import MarutiFronxA from "../../img/Nexa Fronx(Auto)/jpg/nexa-fronx-auto-self-drive-1.jpg"
import MarutiFronxA1 from "../../img/Nexa Fronx(Auto)/jpg/nexa-fronx-auto-self-drive-2.jpg"


import Kwid from "../../img/Renault Kwid (Manual)/jpg/renault-kwid-manual-1.jpg"
import Kwid2 from "../../img/Renault Kwid (Manual)/jpg/renault-kwid-manual-2.jpg"

import KwidA from "../../img/Renault Kwid ( Auto)/jpg/renault-kwid-automatic-1.jpg"
import KwidA1 from "../../img/Renault Kwid ( Auto)/jpg/renault-kwid-automatic-2.jpg"

import KwidAutomatic from "../../img/Renault/kwidauto.png"
// import Thar from "../../img/mahindra/Thar.png";
import Thar from "../../img/Mahindra Thar New-Gen(Auto)/jpg/mahindra-thar-new-gen-auto-self-drive-1.jpg"
import Thar1 from "../../img/Mahindra Thar New-Gen(Auto)/jpg/mahindra-thar-new-gen-auto-self-drive-2.jpg"
import TharM from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-1.jpg"
import TharM1 from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-2.jpg"
import TharM2 from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-3.jpg"

import Thar1stG from "../../img/Mahindra Thar Old Shape(Manual)/jpg/mahindra-thar-old-manual-self-drive-1.jpg"
// import Carens1 from "../../img/kia/Carens1.png";
import Carens from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-1.jpg"
import Carens1 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-2.jpg"
import Carens2 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-3.jpg"
import Carens3 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-4.jpg"
// import Sonet from "../../img/kia/Sonet.png";
import SonetA from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-1.jpg"
import SonetA1 from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-2.jpg"
import SonetA2 from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-3.jpg"
import Sonet from "../../img/Kia Sonet(Manual)/jpg/kia-sonet-manual-self-drive-1.jpg"

import Creta from "../../img/Hyundai Creta(Manual)/jpg/hyundai-creta-manual-self-drive-1.jpg"
import Creta1 from "../../img/Hyundai Creta(Manual)/jpg/hyundai-creta-manual-self-drive-2.jpg"
import FortunerA3 from "../../img/ToyotaUrbanCruiser auto/toyota-fortuner-new-gen-auto-self-drive-4.jpg"
import Hondaii20New from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-1.jpg"
import Hondaii20New1 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-2.jpg"
import Hondaii20New2 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-3.jpg"
import Hondaii20New3 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-4.jpg"
import Toyota from "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-1.jpg"
import Toyota1 from "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-2.jpg"
import Toyota2 from "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-3.jpg"
import HyundaiAlcazarA from "../../img/Hyundai Alcazar ( Auto)/jpg/hyundai-alcazar-automatic-self-drive-1.jpg"
import HyundaiAlcazarA1 from "../../img/Hyundai Alcazar ( Auto)/jpg/hyundai-alcazar-automatic-self-drive-2.jpg"
import HyundaiSantroA from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-1.JPG"
import HyundaiSantroA1 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-2.JPG"
import HyundaiSantroA2 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-3.JPG"
import HyundaiSantroA3 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-4.JPG"
 import HyundaiVenueM from "../../img/Hyundai Venue(Manual)/jpg/hyundai-venue-manual-self-drive-1.jpg"
 import HyundaiVenueM1 from "../../img/Hyundai Venue(Manual)/jpg/hyundai-venue-manual-self-drive-1.jpg"
// import Exter from "../../img/hyundai/Hyundai Exter.png";
import HyundaiExterA from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-1.jpg"
import HyundaiExterA1 from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-2.jpg"
import HyundaiExterA2 from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-3.jpg"
import transmition from "../../img/specificationIcons/Car Type.png";
import fuel from "../../img/specificationIcons/Fuel Type.png";
import mileage from "../../img/specificationIcons/Mileage.png";
import sterring from "../../img/specificationIcons/Power Steering.png";
import Engine from "../../img/specificationIcons/Engine.png";
import seater from "../../img/specificationIcons/Seater.png";
import WhatsApp from "../../img/watsapp/watsapp.svg";
import Stars from "../../img/Group 97.png";

import "./style.css";

const HotOffers = () => {
  const [showAllCars, SetShowallCars] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [phoneNO, setPhoneNO] = useState(null);
  const [selectedCarBrand, setSelectedCarBrand] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [displayLogo, setDisplayLogo] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);
  const [pickuptime, setpickupTime] = useState("");
  const [droptime, setDropTime] = useState("");

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    selectedPlace: '',
    dropOffLocation: '',
    selectedDate: null,
    timeOfPickUp: '',
    selectedDepartureDate: null,
    timeOfDropOff: '',
    selectedCarBrand: '',
  });
  const whatsappNumber = "+919850114297";
  console.log("selectedCarBrand", selectedCarBrand);
  const MahindraData = [
    {
      brand: "Mahindra Thar Old Shape(Manual)",
      image:[Thar1stG],
      // price: 4000,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 14.4,
        engine_cc: 2393,
        seater: "7",
      },
    },
    {
      brand: "Mahindra Thar New-Gen(Auto)",
      image: [Thar, Thar1],
      // price: 4000,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 15.2,
        engine_cc: 1997,
        seater: "4",
      },
    },
    {
      brand: "Mahindra Thar New-Gen(Manual)",
      image:[TharM, TharM1, TharM2],
      // price: 4000,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 14.4,
        engine_cc: 2393,
        seater: "7",
      },
    },
  ];
  const ToyotaData = [
    {
      brand: "Toyota Urban Cruiser(Auto)",
      image: [Toyota, Toyota1, Toyota2],
      // price: 1300,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 19.6,
        engine_cc: 1198,
        seater: "5",
      },
    },
    {
      brand: "Toyota Fortuner New-Gen(Auto)",
      image: [Fortuner, FortunerA1, FortunerA2, FortunerA3],
      // price: 7000,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 10,
        engine_cc: 2694,
        seater: "7",
      },
    },
    {
      brand: "Innova Crysta(Manual)",
      image:[InnovaM, InnovaM1, InnovaM2],
      // price: 4000,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 14.4,
        engine_cc: 2393,
        seater: "7",
      },
    },
    
  ];
  const MarutiData = [
    {
      brand: "New-Gen Swift(Automatic)",
      image: [Swift3rdA, Swift3rdG1A, Swift3rdG2A],
      // price: 1200,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 21.2,
        engine_cc: 1198,
        seater: "5",
      },
    },
    {
      brand: "Maruti Suzuki Baleno(Manual)",
      image: [Baleno, Baleno1],
      // price: 1400,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 21.0,
        engine_cc: 1197,
        seater: "5",
      },
    },
    {
      brand: "Nexa Jimny(Auto)",
      image: [MarutiJimnyA, MarutiJimnyA1, MarutiJimnyA2, MarutiJimnyA3],
      // price: 2500,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 19.0,
        engine_cc: 1462,
        seater: "7",
      },
    },
  ];
  const RenaultData = [
    {
      brand: "Renault Kwid(Manual)",
      image: [Kwid, Kwid2],
      // price: 1100,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 21.2,
        engine_cc: 1198,
        seater: "5",
      },
    },
    {
      brand: "Renault Kwid(Auto)",
      image: [KwidA, KwidA1],
      // price: 1400,
      details: {
        power: "power",
        transmition: "Automatic",
        type: "P",
        mileage: 21.2,
        engine_cc: 1198,
        seater: "5",
      },
    },
  ];
  const KiaData = [
    {
      brand: "Kia Carens(Manual)",
      image: [Carens, Carens1, Carens2, Carens3],
      // price: 2500,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: "17.9",
        engine_cc: "1493",
        seater: "5",
      },
    },
    {
      brand: "Kia Sonet(Auto)",
      image: [SonetA, SonetA1, SonetA2], // Assuming you have the image path or reference
      // price: 4500,
      details: {
        power: "power",
        transmition: "Automatic",
        type: "p",
        // available: false, // Set availability status 
        seater: 5,
        mileage: "16.13",
        engine_cc: "1987",
      },
    },
    {
      brand: "Kia Sonet(Manual)",
      image: [Sonet],
      // price: 3500,
      details: {
        power: "power",
        transmition: "manual",
        type: "p",
        mileage: 18.2,
        engine_cc: 1197,
        seater: 5,
      },
    },
  ];
  const HyundaiData = [
    {
      brand: "Hyundai Creta(Manual)",
      image: [Creta, Creta1],
      // price: 2500,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 17.7,
        engine_cc: 1497,
        seater: "5",
      },
    },
    {
      brand: "Hyundai i20 New-Gen(Manual)",
      image: [Hondaii20New, Hondaii20New1, Hondaii20New2, Hondaii20New3],
      // price: 1600,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 20.35,
        engine_cc: 1197,
        seater: "5",
      },
    },
    {
      brand: "Hyundai Alcazar (Auto)",
      image: [HyundaiAlcazarA, HyundaiAlcazarA1],
      // price: 4000,
      details: {
        power: "power",
        transmition: "Automatic",
        type: "P",
        mileage: "18",
        engine_cc: "1482",
        seater: "7",
      },
    },
  ];
  const AllCarsData = [
    {
      brand: "Kia Sonet(Manual)",
      image: [Sonet],
      // price: 3500,
      details: {
        power: "power",
        transmition: "manual",
        type: "P",
        mileage: 18.3,
        engine_cc: 998,
        seater: "5",
      },
    },
    // {
    //   brand: "Toyota Fortuner New Gen(A)",
    //   image: [FortunerM, FortunerM1, FortunerM2],
    //   // price: 7000,
    //   details: {
    //     power: "power",
    //     transmition: "Auto",
    //     type: "P",
    //     mileage: 10,
    //     engine_cc: 2694,
    //     seater: "7",
    //   },
    // },
    {
      brand: "Toyota Fortuner New-Gen(Auto)",
      image: [Fortuner, FortunerA1, FortunerA2, FortunerA3], // Assuming you have the image path or reference
      // price: 7000,
      details: {
        power: "power",
        transmition: "Automatic",
        type: "p",
        mileage: 10.26,
        engine_cc: 2755,
        seater: 7,
      },
    },
    {
      brand: "Hyundai Alcazar(Auto)",
      image: [HyundaiAlcazarA, HyundaiAlcazarA1],
      // price: 4000,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: "18",
        engine_cc: "1482",
        seater: "7",
      },
    },
    {
      brand: "Hyundai Exter(Auto)",
      image: [HyundaiExterA, HyundaiExterA1,HyundaiExterA2],
      // price: 2500,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 19.2,
        engine_cc: 1197,
        seater: "5",
      },
    },
    {
      brand: "Nexa Fronx(Auto)",
      image: [MarutiFronxA, MarutiFronxA1],
      // price: 2500,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: 22.89,
        engine_cc: 1197,
        seater: "5",
      },
    },
    {
      brand: "Toyota Urban Cruiser(Auto)",
      image: [Toyota, Toyota1, Toyota2],
      // price: 3000,
      details: {
        power: "power",
        transmition: "Auto",
        type: "P",
        mileage: "18.7",
        engine_cc: "1462",
        seater: "5",
      },
    },
  ];
  
  const combinedArray = [
    ...MahindraData,
    ...ToyotaData,
    ...MarutiData,
    ...RenaultData,
    ...KiaData,
    ...HyundaiData,
    ...AllCarsData,
  ];

  console.log(combinedArray);
 
  const handlePlaceSelection = (event) => {
    setSelectedPlace(event.target.value);
    setFormData({
      ...formData,
      selectedPlace: event.target.value,
    });
  };

  const handlePhoneNo = (event) => {
    setPhoneNO();
    console.log("phone",phoneNO);
    setFormData({
      ...formData,
      phoneNumber: phoneNO,
    });
  };

  const handleCarSelection = (event) => {
    console.log(event);
    const selectedValue = event.target.value;
    const selectedCarObject = combinedArray.find(
      (car) => car.brand === selectedValue
    );
    console.log("selectedCarObject",selectedCarObject);
    setSelectedCarBrand(selectedCarObject.brand);
    setFormData({
      ...formData,
      selectedCarBrand: selectedCarObject.brand,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' represents the locale for dd/mm/yyyy format
    setFormData({
      ...formData,
      selectedDate: formattedDate,
    });
  };
  const handleDepartureDateChange = (date) => {
    setSelectedDepartureDate(date);
    const formattedDate = date.toLocaleDateString('en-GB');
    setFormData({
      ...formData,
      selectedDepartureDate: formattedDate,
    });
  };

  const modelOpen = (brand) => {
    setShowModal(true);
    setSelectedCarBrand(brand);
    setFormData({
      ...formData,
      selectedCarBrand: brand,
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const { t } = useTranslation();

  const toggleCondition = () => {
    SetShowallCars(false);
    console.log(showAllCars);
  };

  const handleDropLocation = (e) => {
 
    
    setFormData({
      ...formData,
     dropOffLocation: e.target.value,
      
    });
  };
  const handleDroptime = (e) => {
 
    
    setFormData({
      ...formData,
      timeOfDropOff: e.target.value,
      
    });
  };
  const handlePickUptime = (e) => {
 
    
    setFormData({
      ...formData,
      timeOfPickUp: e.target.value,
      
    });
  };
  const handleInputChange = (e) => {
    const {  name, value } = e.target;
    
    setFormData({
      ...formData,
      [name]: value,
      
    });
  };
  const constructWhatsAppMessage = () => {
    // Construct the message using form data
    const message = `Hi I am interested in renting a car from Viegas car Rentals Goa and would like to request a quote.\n*Name:* ${formData.name}\n*PickUp From:* ${formData.selectedPlace}\n*PickUp Date & Time:* ${formData.selectedDate}, ${formData.timeOfPickUp}\n*Drop Off location:* ${formData.dropOffLocation}\n*Drop Off Date & Time:* ${formData.selectedDepartureDate}, ${formData.timeOfDropOff}\n*Selected Car:* ${formData.selectedCarBrand}`;
    return encodeURIComponent(message);
  };
  const SubmitHandler = (e) => {
    // navigate("/car-listing")
    e.preventDefault();
  };

  return (
    <section className="gauto-offers-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              {/* <h4>{t("come_with")}</h4> */}
              {/* <h2>{t("hot_offers")}</h2> */}
              <h2>Discover Our Best Self-drive Cars In Goa</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="offer-tabs" id="offerTab">
              <Tabs
                defaultActiveKey="all"
                transition={true}
                id="uncontrolled-tab-example"
                onClick={toggleCondition}
              >
                {/* All Brands Start */}
                {/* <Tab
                  eventKey="all"
                  title=""
                  style={{ backgroundColor: "white" }}
                  // disabled
                > 
                 
                </Tab> */}
                {/* All Brands End */}

                <Tab
                  // onClick={()=> console.log("hello")}
                  // onClick={(e)=>{console.log("clicked");}}
                  onClick={toggleCondition}
                  eventKey="Toyota"
                  title={
                    <span onClick={toggleCondition}>
                      <img
                        style={{ width: "26px", margin: "5px" }}
                        src={img1}
                        alt="T"
                      />{" "}
                      Toyota
                    </span>
                  }
                >
                  <Row>
                    {ToyotaData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                           <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(`${car.details.mileage} k`)}
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(`${car.details.engine_cc} cc`)}
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                <Tab
                  //  onClick={()=> console.log("hello")}
                  eventKey="Maruti"
                  title={
                    <span onClick={toggleCondition}>
                      <img
                        style={{ width: "20px", margin: "5px" }}
                        src={img2}
                        alt="M"
                      />{" "}
                      Maruti
                    </span>
                  }
                >
                  <Row>
                    {MarutiData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                           <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(`${car.details.mileage} k`)}
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(`${car.details.engine_cc} cc`)}
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                <Tab
                  //  onClick={()=> console.log("hello")}
                  eventKey="Renault"
                  title={
                    <span onClick={toggleCondition}>
                      <img
                        style={{ width: "18px", margin: "3px" }}
                        src={img3}
                        alt="R"
                      />{" "}
                      Renault
                    </span>
                  }
                >
                  <Row>
                    {RenaultData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                           <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(`${car.details.mileage} k`)}
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(`${car.details.engine_cc} cc`)}
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                <Tab
                  eventKey="Mahindra"
                  title={
                    <span onClick={toggleCondition}>
                      <img style={{ width: "21px" }} src={img4} alt="M" />{" "}
                      Mahindra
                    </span>
                  }
                >
                  <Row>
                  {MahindraData.map((car, index) => (
                    
                      <Col lg={4}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                          <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              {/* Assuming Stars is a static image */}
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />{" "}
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />{" "}
                                  {t(`${car.details.mileage} k`)}
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />{" "}
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />{" "}
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />{" "}
                                  {t(`${car.details.engine_cc} cc`)}
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />{" "}
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="whatsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                  ))}
                  </Row>
                </Tab>

                <Tab
                  // onClick={()=> console.log("hello")}
                  eventKey="Kia"
                  title={
                    <span onClick={toggleCondition}>
                      <img
                        style={{ width: "30px", margin: "3px" }}
                        src={img5}
                        alt="R"
                      />{" "}
                      Kia
                    </span>
                  }
                >
                  <Row>
                    {KiaData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                           <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(car.details.mileage)}K
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(car.details.engine_cc)}cc
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>

                <Tab
                  // onClick={()=> console.log("hello")}
                  eventKey="Hyundai"
                  title={
                    <span onClick={toggleCondition}>
                      <img
                        style={{ width: "25px", margin: "3px" }}
                        src={img6}
                        alt="R"
                      />{" "}
                      Hyundai
                    </span>
                  }
                >
                  <Row>
                    {HyundaiData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                            <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(car.details.mileage)}K
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(car.details.engine_cc)}cc
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater} S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              </Tabs>

              {showAllCars ? (
                <div>
                  <Row>
                    {AllCarsData.map((car, index) => (
                      <Col lg={4} key={index}>
                        <div className="single-offers">
                          {/* <div className="offer-image">
                            <Link to="">
                              <img src={car.image} alt={`offer ${index + 1}`} />
                            </Link>
                          </div> */}
                            <Carousel >
                             {car.image.length > 0 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                     <img src={car.image[0]} alt={`offer ${index + 1}`} />
                                  </div>
                                </Carousel.Item>
                                )}
                             {car.image.length > 1 && (
                                <Carousel.Item>
                                 <div className="offer-image">
                                     <img src={car.image[1]} alt={`offer ${index + 2}`} />
                                 </div>
                                </Carousel.Item>
                                )}
                            {car.image.length > 2 && (
                                <Carousel.Item>
                                  <div className="offer-image">
                                       <img src={car.image[2]} alt={`offer ${index + 3}`} />
                                  </div>
                               </Carousel.Item>
                               )}
                            {car.image.length > 3 && (
                                 <Carousel.Item>
                                   <div className="offer-image">
                                     <img src={car.image[3]} alt={`offer ${index + 4}`} />
                                   </div>
                                 </Carousel.Item>
                               )}
                        </Carousel>
                          <div className="offer-text">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "left",
                                justifyContent: "space-between",
                                fontSize: "19.5px",
                              }}
                            >
                              <Link to="">
                                <p style={{ color: "black" }}>{car.brand}</p>
                              </Link>
                              {/* <h4 style={{ color: "#FFDA27" }}>
                                ₹{car.price}
                                <span>/ {t("day")}</span>
                              </h4> */}
                            </div>
                            <div
                              style={{
                                margin: "1%",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <img src={Stars} alt="5 star ratings" />
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={transmition} alt="" />
                                  {t(car.details.transmition)}
                                </li>
                                <li>
                                  <img src={mileage} alt="fuel" />
                                  {t(car.details.mileage)}K
                                </li>
                                <li>
                                  <img src={fuel} alt="fuel" />
                                  {t(car.details.type)}
                                </li>
                              </ul>
                            </div>
                            <div className="specification">
                              <ul
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li>
                                  <img src={sterring} alt="fuel" />
                                  {t(car.details.power)}
                                </li>
                                <li>
                                  <img src={Engine} alt="fuel" />
                                  {t(car.details.engine_cc)}cc
                                </li>
                                <li>
                                  <img src={seater} alt="fuel" />
                                  {t(`${car.details.seater}S`)}
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn-container">
                              <Link
                                to="#"
                                onClick={() => modelOpen(car.brand)}
                                className="offer-btn-1"
                              >
                                <img
                                  className="watsapp-icon"
                                  src={WhatsApp}
                                  alt="whatsapp"
                                />{" "}
                                {t("Book Now on WhatsApp")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <img width={130} height={50} src={logo} alt="Veigas Cars" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Add content for your modal here */}
            {/* <p>This is the modal content.</p> */}
            <Row className="align-items-center ">
              <Col md={12}>
                <div className="find-form">
                  <form onSubmit={SubmitHandler}>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input onChange={handleInputChange} name="name" type="text" placeholder={t("Name")} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <PhoneInput
                            country={"in"} // Specify the default country using its ISO code (lowercase)
                            enableSearch // Enable search functionality for country selection
                            inputClass=" custom-phone-input" // Apply the Bootstrap form-control class for styling
                            placeholder="Enter phone number"
                            // onChange={}
                            value={phoneNO}
                            onChange={handlePhoneNo}
                            buttonStyle={{ backgroundColor: "white" }} // Set the flag image for the default country
                            inputStyle={{ borderRadius: "0px" }}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <select
                          name="place"
                            value={selectedPlace}
                            onChange={handlePlaceSelection}
                            placeholder="Select Pick Up Location"
                          >
                            <option disabled value="">
                              {t("Select Pick Up Location")}
                            </option>
                            <option value={t("Panjim")}>{t("Panjim")}</option>
                            <option value={t("Mapusa")}>{t("Mapusa")}</option>
                            <option value={t("Margao")}>{t("Margao")}</option>
                            <option value={t("Airports")}>
                              {t("Airports")}
                            </option>
                            <option value={t("Railways")}>
                              {t("Railways")}
                            </option>
                            <option value={t("North Goa")}>
                              {t("North Goa")}
                            </option>
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input
                          name="dropOffLocation"
                          onChange={handleDropLocation}
                            type="text"
                            placeholder={t("Enter Drop Off Location")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Arrival"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <p>
                            <input
                              type="text"
                              placeholder={t("Time of Pick Up")}
                              onChange={handlePickUptime}
                            />
                          </p>{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDepartureDate}
                              onChange={handleDepartureDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Departure"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input
                            type="text"
                            placeholder={t("Time of Drop Off")}
                            onChange={handleDroptime}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <select
                            value={selectedCarBrand}
                            onChange={handleCarSelection}
                            placeholder="Select car"
                          >
                            {combinedArray.map((car, index) => (
                              <option key={index} value={car.brand}>
                                {car.brand}
                              </option>
                            ))}
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <div className="offer-btn-container">
                      <a
                         href={`https://wa.me/${whatsappNumber}?text=${constructWhatsAppMessage()}`}
                        className="offer-btn-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        
                      >
                        <img
                          className="watsapp-icon"
                          src={WhatsApp}
                          alt="whatsapp"
                        />{" "}
                        Book Now on WhatsApp
                      </a>
                      
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
};

export default HotOffers;
