import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
// import { registerLicense } from "@syncfusion/ej2-base";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import "./style.css";
import { use } from "i18next";
import { useNavigate } from "react-router-dom";

// registerLicense(
//   "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jXX9cc3dRR2BbWEM="
// );
const FindCar = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [selectedPlace, setSelectedPlace] = useState("");
  const [dropLocation, setdropLocation] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);

  const SubmitHandler = (e) => {
    navigate(`/car-listing?`)
    // navigate("/car-listing")
    e.preventDefault();
  };

  const handlePlaceSelection = (event) => {
    setSelectedPlace(event.target.value);
    // setFormData({
    //   ...formData,
    //   selectedPlace: event.target.value,
    // });
  };

  const handleDropLocation = (e) => {
 
    
    setdropLocation(e.target.value)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' represents the locale for dd/mm/yyyy format
    
  };
  
  const handleDepartureDateChange = (date) => {
    setSelectedDepartureDate(date);
    const formattedDate = date.toLocaleDateString('en-GB');
  
  };

  return (
    <section className="gauto-find-area">
      <Container>
        <Row>
          <Col md={12}>
            <div className="find-box ">
              <Row className="align-items-center ">
                <Col md={4}>
                  <div className="find-text color-overlay">
                    <h2>{t("Search Best Self drive Cars")}</h2>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="find-form">
                    <form onSubmit={SubmitHandler}>
                      <Row>
                        <Col md={3}>
                          <p>
                        
                          <select
                            name="place"
                            value={selectedPlace}
                            onChange={handlePlaceSelection}
                            placeholder="Select Pick Up Location"
                          >
                            <option disabled value="">{t("Select Pick Up Location")}</option>
                            <option value={t("Panjim")}>{t("Panjim")}</option>
                            <option value={t("Mapusa")}>{t("Mapusa")}</option>
                            <option value={t("Margao")}>{t("Margao")}</option>
                            <option value={t("Airports")}>{t("Airports")}</option>
                            <option value={t("Railways")}>{t("Railways")}</option>
                            <option value={t("North Goa")}>{t("North Goa")}</option>
                          </select>
                    
                          </p>
                        </Col>
                        <Col md={3}>
                          <p>
                          <input
                          name="dropOffLocation"
                          onChange={handleDropLocation}
                            type="text"
                            placeholder={t("Enter Drop Off Location")}
                          />
                          </p>
                        </Col>
                        <Col md={3}>
                          <p>
                            <select placeholder={t("Type of car")}>
                            <option  value="">{t("Type of Car")}</option>
                              <option>{t("Sedan")}</option>
                              <option>{t("SUV")}</option>
                              <option>{t("Hatchback")}</option>
                              <option>{t("Convertible")}</option>
                            </select>
                          </p>
                        </Col>
                        <Col md={3}>
                          <p>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Arrival"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          {/* add our date picker */}
                          {/* <p>
                            <DatePickerComponent
                              id="datepicker"
                              placeholder={t("journey_date")}
                            ></DatePickerComponent>
                          </p> */}
                          <p>
                            <input type="text" placeholder={"Time Of Pick Up"} />
                          </p>
                        </Col>
                        <Col md={3}>
                          <p>
                            {/* add our datepicker */}
                            {/* <TimePickerComponent
                              id="timepicker"
                              placeholder={t("journey_time")}
                            ></TimePickerComponent> */}
                  
                   
                             <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDepartureDate}
                              onChange={handleDepartureDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Departure"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                   
                          </p>
                        </Col>
                        <Col md={3}>
                          {/* <p>
                            <button type="submit" className="gauto-theme-btn">
                              {t("find_car")}
                            </button>
                          </p> */}
                        </Col>
                        <Col md={3}>
                          <p>
                            <button type="submit" className="gauto-theme-btn">
                              {t("find_car")}
                            </button>
                          </p>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FindCar;
