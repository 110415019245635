import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Dropdown, Form, Tab, Modal, Carousel } from "react-bootstrap";
import {
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaAngleDoubleRight,
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../img/footer-logo.png";
import img2 from "../../img/nissan-offer.png";
import img3 from "../../img/audi-offer.png";
import img4 from "../../img/bmw-offer.png";
import img5 from "../../img/toyota-offer-2.png";
import img6 from "../../img/marcedes-offer.png";
import liva from "../../img/toyota/Liva.png";
// import Fortuner from "../../img/toyota/New Fortuner.png";
// import NewFortunerA from "../../img/ToyotaUrbanCruiser auto/NewFortunerA.PNG"
import NewFortunerA from "../../img/ToyotaUrbanCruiser auto/toyota-fortuner-new-gen-auto-self-drive-1.jpg"
import NewFortunerA1 from "../../img/ToyotaUrbanCruiser auto/toyota-fortuner-new-gen-auto-self-drive-2.jpg"
import NewFortunerA2 from "../../img/ToyotaUrbanCruiser auto/toyota-fortuner-new-gen-auto-self-drive-3.jpg"
import NewFortunerA3 from "../../img/ToyotaUrbanCruiser auto/toyota-fortuner-new-gen-auto-self-drive-4.jpg"
import FortunerA1 from "../../img/ToyotaUrbanCruiser auto/Fortuner.jpg"
import FortunerA2 from "../../img/ToyotaUrbanCruiser auto/Fortuner2.jpg"
import FortunerA3 from "../../img/ToyotaUrbanCruiser auto/Fortuner3.jpg"

import InnovaM from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-1.jpg"
import InnovaM1 from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-2.jpg"
import InnovaM2 from "../../img/Innova Crysta(Manual)/jpg/innova-crysta-manual-self-drive-3.jpg"

import InnovaA from "../../img/Innova Crysta(Automatic)/jpg/innova-crysta-automatic-self-drive-1.jpg"
import InnovaA1 from "../../img/Innova Crysta(Automatic)/jpg/innova-crysta-automatic-self-drive-2.jpg"
import InnovaA2 from "../../img/Innova Crysta(Automatic)/jpg/innova-crysta-automatic-self-drive-3.jpg"
import InnovaA3 from "../../img/Innova Crysta(Automatic)/jpg/innova-crysta-automatic-self-drive-4.jpg"
// import Glanza from "../../img/toyota/Glanza.png";
import ToyotaGlanza from "../../img/ToyotaUrbanCruiser auto/toyota-glanza-manual-self-drive-1.jpg"
import ToyotaGlanza1 from "../../img/ToyotaUrbanCruiser auto/toyota-glanza-manual-self-drive-2.jpg"
import ToyotaGlanza2 from "../../img/ToyotaUrbanCruiser auto/toyota-glanza-manual-self-drive-3.jpg"
//import GlanzaAuto from "../../img/toyota/GlanzaAuto.png";
import ToyotaGlanzaA from "../../img/ToyotaUrbanCruiser auto/toyota-glanza-auto-self-drive-1.jpg"
import ToyotaGlanzaA1 from "../../img/ToyotaUrbanCruiser auto/toyota-glanza-auto-self-drive-2.jpg"
import Crysta from "../../img/toyota/Crysta.png";
// import InnovaHycross from "../../img/toyota/InnovaHycross.png";
import ToyotaHycross from "../../img/Innova Hycross(Automatic)/jpg/innova-hycross-automatic-self-drive-1.jpg"
//import UrbanCruiser from "../../img/toyota/Toyota Urban Cruiser.png";
import Toyota from  "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-1.jpg"
import Toyota1 from "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-2.jpg"
import Toyota2 from "../../img/ToyotaUrbanCruiser auto/toyota-urban-cruiser-auto-self-drive-3.jpg"
// import HondaBrio from "../../img/honda/Honda Brio.png";
import HondaBrio from "../../img/Honda Brio(Manual)/jpg/honda-brio-manual-self-drive-1.jpg"
import HondaBrio2 from "../../img/Honda Brio(Manual)/jpg/honda-brio-manual-self-drive-2.jpg"
import HondaBrio3 from "../../img/Honda Brio(Manual)/jpg/honda-brio-manual-self-drive-3.jpg"
import HondaCity from "../../img/honda/Honda City.png";
// import Swift from "../../img/swift/New-Gen Swift.png";
import Swift from "../../img/Maruti Suzuki Swift Second-Gen(Manual)/jpg/maruti-suzuki-swift-second-gen-manual-self-drive-1.jpg"
import SwiftFriestG1 from "../../img/Maruti Suzuki Swift Second-Gen(Manual)/jpg/maruti-suzuki-swift-second-gen-manual-self-drive-2.jpg"
// import MarutiSecondGenSwift from "../../img/swift/Swift dzire.png";
import SwiftSecondGen from "../../img/New-Gen Swift(Manual)/jpg/suzuki-swift-new-gen-manual-self-drive-1.jpg"
import SwiftSecondG1 from "../../img/New-Gen Swift(Manual)/jpg/suzuki-swift-new-gen-manual-self-drive-1.jpg"
import SwiftSecondG2 from "../../img/New-Gen Swift(Manual)/jpg/suzuki-swift-new-gen-manual-self-drive-1.jpg"
import Swift3rdA from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-1.jpg"
import Swift3rdG1A from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-2.jpg"
import Swift3rdG2A from "../../img/New-Gen Swift(Automatic)/jpg/swift-new-gen-automatic-self-drive-3.jpg"
import Dzire from "../../img/swift/dzire.png";
// import Baleno from "../../img/swift/Baleno.png";
import Baleno from "../../img/Maruti Suzuki Baleno(Manual)/jpg/maruti-suzuki-baleno-manual-self-drive-1.jpg"
import Baleno1 from "../../img/Maruti Suzuki Baleno(Manual)/jpg/maruti-suzuki-baleno-manual-self-drive-2.jpg"

import BalenoA from "../../img/MarutiBaleno manual/BalenoA.PNG"
import BalenoA1 from "../../img/New-Gen Baleno(Automatic)/jpg/baleno-new-gen-automatic-self-drive-1.jpg"
import BalenoA2 from "../../img/New-Gen Baleno(Automatic)/jpg/baleno-new-gen-automatic-self-drive-2.jpg"
import BalenoA3 from "../../img/New-Gen Baleno(Automatic)/jpg/baleno-new-gen-automatic-self-drive-3.jpg"
import NewBalenoM from "../../img/New-Gen Baleno(Manual)/jpg/baleno-new-gen-automatic-self-drive-1.jpg"
import NewBalenoM1 from "../../img/New-Gen Baleno(Manual)/jpg/baleno-new-gen-automatic-self-drive-2.jpg"
import NewBalenoM2 from "../../img/New-Gen Baleno(Manual)/jpg/baleno-new-gen-automatic-self-drive-3.jpg"

import NewErtigaA from "../../img/New-Gen Ertiga(Automatic)/jpg/ertiga-new-gen-self-drive-automatic-1.jpg"
import NewErtigaA1 from "../../img/New-Gen Ertiga(Automatic)/jpg/ertiga-new-gen-self-drive-automatic-2.jpg"
import NewErtigaA2 from "../../img/New-Gen Ertiga(Automatic)/jpg/ertiga-new-gen-self-drive-automatic-3.jpg"
import NewErtigaM from "../../img/New-Gen Ertiga(Manual)/jpg/new-gen-ertiga-manual-self-drive-1.jpg"

import ScorpioM from "../../img/Mahindra Scorpio N(Manual)/jpg/mahindra-scorpio-new-manual-self-drive-1.jpg"
import ScorpioM1 from "../../img/Mahindra Scorpio N(Manual)/jpg/mahindra-scorpio-new-manual-self-drive-2.jpg"
import ScorpioM2 from "../../img/Mahindra Scorpio N(Manual)/jpg/mahindra-scorpio-new-manual-self-drive-3.jpg"
import ScorpioM3 from "../../img/Mahindra Scorpio N(Manual)/jpg/mahindra-scorpio-new-manual-self-drive-4.jpg"
import AudiA4 from "../../img/Audi A4 & Q3/audi-a4-automatic-self-drive-4.jpg"
import AudiA41 from "../../img/Audi A4 & Q3/audi-a4-automatic-self-drive-2.jpg"
import AudiA42 from "../../img/Audi A4 & Q3/audi-a4-automatic-self-drive-5.jpg"
import AudiA43 from "../../img/Audi A4 & Q3/audi-a4-automatic-self-drive-3.jpg"
import AudiA44 from "../../img/Audi A4 & Q3/AudiA4(4).jpg"

import AudiQ3 from "../../img/Audi A4 & Q3/audi-q3-automatic-self-drive-1.jpg"
import AudiQ31 from "../../img/Audi A4 & Q3/audi-q3-automatic-self-drive-2.jpg"
import AudiQ32 from "../../img/Audi A4 & Q3/audi-q3-automatic-self-drive-3.jpg"
import AudiQ33 from "../../img/Audi A4 & Q3/audi-q3-automatic-self-drive-4.jpg"

import Nissan from "../../img/Nissan Magnite/nissan-magnite-automatic-self-drive-1.jpg"
import image1 from "../../img/Nissan Magnite/nissan-magnite-automatic-self-drive-2.jpg"
import image2 from "../../img/Nissan Magnite/nissan-magnite-automatic-self-drive-3.jpg"
import image3 from "../../img/Nissan Magnite/nissan-magnite-automatic-self-drive-4.jpg"
import image4 from "../../img/Nissan Magnite/nissan-magnite-automatic-self-drive-5.jpg"
// import image5 from "../../img/Nissan Magnite/image5.png"
import Ertiga from "../../img/swift/Ertiga.png";
import XL6 from "../../img/swift/nexa xl6.png";
import Ciaz from "../../img/swift/Ciaz.png";
//import Brezza from "../../img/swift/Brezza.png";
import MarutiBrezzaM from "../../img/Maruti Suzuki Brezza(Manual)/jpg/maruti-suzuki-brezza-manual-self-drive-1.jpg"
import MarutiBrezzaM1 from "../../img/Maruti Suzuki Brezza(Manual)/jpg/maruti-suzuki-brezza-manual-self-drive-2.jpg"

import MarutiJimnyA from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-1.jpg"
import MarutiJimnyA1 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-2.jpg"
import MarutiJimnyA2 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-3.jpg"
import MarutiJimnyA3 from "../../img/Nexa Jimny(Auto)/jpg/nexa-jimny-auto-self-drive-4.jpg"

import NexaXL6A from "../../img/Nexa XL6(Automatic)/jpg/nexa-xl6-automatic-self-drive-1.jpg"
import NexaXL6A1 from "../../img/Nexa XL6(Automatic)/jpg/nexa-xl6-automatic-self-drive-2.jpg"

import MarutiFronxA from "../../img/Nexa Fronx(Auto)/jpg/nexa-fronx-auto-self-drive-1.jpg"
import MarutiFronxA1 from "../../img/Nexa Fronx(Auto)/jpg/nexa-fronx-auto-self-drive-2.jpg"
import MarutiFronxA2 from "../../img/Nexa Fronx(Auto)/jpg/nexa-fronx-auto-self-drive-3.jpg"
// import Ignis from "../../img/swift/Nexa ignis.png";
import Ignis from "../../img/Nexa Ignis(Manual)/jpg/nexa-ignis-manual-1.jpg"
import Ignis1 from "../../img/Nexa Ignis(Manual)/jpg/nexa-ignis-manual-2.jpg"
import Ignis2 from "../../img/Nexa Ignis(Manual)/jpg/nexa-ignis-manual-3.jpg"

import VitaraA from "../../img/Nexa Grand Vitara(Automatic)/jpg/nexa-grand-vitara-automatic-self-drive-1.jpg"
import VitaraA1 from "../../img/Nexa Grand Vitara(Automatic)/jpg/nexa-grand-vitara-automatic-self-drive-2.jpg"
import VitaraA2 from "../../img/Nexa Grand Vitara(Automatic)/jpg/nexa-grand-vitara-automatic-self-drive-3.jpg"
import VitaraA3 from "../../img/Nexa Grand Vitara(Automatic)/jpg/nexa-grand-vitara-automatic-self-drive-4.jpg"
// import Kwid from "../../img/Renault Kwid manual.png";
import Kwid from "../../img/Renault Kwid (Manual)/jpg/renault-kwid-manual-1.jpg"
import Kwid2 from "../../img/Renault Kwid (Manual)/jpg/renault-kwid-manual-2.jpg"

import KwidA from "../../img/Renault Kwid ( Auto)/jpg/renault-kwid-automatic-1.jpg"
import KwidA1 from "../../img/Renault Kwid ( Auto)/jpg/renault-kwid-automatic-2.jpg"

import KwidAutomatic from "../../img/Renault/kwidauto.png"
// import Thar from "../../img/mahindra/Thar.png";
import Thar from "../../img/Mahindra Thar New-Gen(Auto)/jpg/mahindra-thar-new-gen-auto-self-drive-1.jpg"
import Thar1 from "../../img/Mahindra Thar New-Gen(Auto)/jpg/mahindra-thar-new-gen-auto-self-drive-2.jpg"
import Thar2 from "../../img/Mahindra Thar New-Gen(Auto)/jpg/mahindra-thar-new-gen-auto-self-drive-3.jpg"
import TharM from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-1.jpg"
import TharM1 from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-2.jpg"
import TharM2 from "../../img/Mahindra Thar New-Gen(Manual)/jpg/mahindra-thar-new-gen-manual-self-drive-3.jpg"

import Thar1stG from "../../img/Mahindra Thar Old Shape(Manual)/jpg/mahindra-thar-old-manual-self-drive-1.jpg"
// import Carens1 from "../../img/kia/Carens1.png";
import Carens from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-1.jpg"
import Carens1 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-2.jpg"
import Carens2 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-3.jpg"
import Carens3 from "../../img/Kia Carens(Manual)/jpg/kia-carens-manual-self-drive-4.jpg"
// import Sonet from "../../img/kia/Sonet.png";
import SonetA from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-1.jpg"
import SonetA1 from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-2.jpg"
import SonetA2 from "../../img/Kia Sonet(Auto)/jpg/kia-sonet-automatic-self-drive-3.jpg"
import Sonet from "../../img/Kia Sonet(Manual)/jpg/kia-sonet-manual-self-drive-1.jpg"

// import Carens2 from "../../img/kia/Carens2.png";

// import Creta from "../../img/hyundai/Creta.png";
import Creta from "../../img/Hyundai Creta(Manual)/jpg/hyundai-creta-manual-self-drive-1.jpg"
import Creta1 from "../../img/Hyundai Creta(Manual)/jpg/hyundai-creta-manual-self-drive-2.jpg"
import CretaA from "../../img/Hyundai Creta(Automatic)/jpg/hyundai-creta-automatic-self-drive-1.jpg"
import CretaA1 from "../../img/Hyundai Creta(Automatic)/jpg/hyundai-creta-automatic-self-drive-2.jpg"
import CretaA2 from "../../img/Hyundai Creta(Automatic)/jpg/hyundai-creta-automatic-self-drive-3.jpg"

import CretaNewA1 from "../../img/Hyundai Creta New-Gen(Automatic)/jpg/hyundai-creta-new-gen-automatic-self-drive-1.jpg"
import CretaNewA2 from "../../img//Hyundai Creta New-Gen(Automatic)/jpg/hyundai-creta-new-gen-automatic-self-drive-2.jpg"
//import i10 from "../../img/hyundai/Hyundai Grand i10.png";
import Hondai10 from "../../img/Hyundai i10 Grand ( Manual)/jpg/hyundai-i10-grand-manual-self-drive-1.jpg"
// import i20 from "../../img/hyundai/i20.png";
import Hondaii20New from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-1.jpg"
import Hondaii20New1 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-2.jpg"
import Hondaii20New2 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-3.jpg"
import Hondaii20New3 from "../../img/Hyundai i20 New-Gen (Manual)/jpg/hyundai-i20-new-gen-manual-self-drive-4.jpg"
import Hondaii20A from "../../img/Hyundai i20 New-Gen(Automatic)/jpg/hyundai-i20-new-gen-automatic-self-drive-1.jpg"
import Hondaii20A1 from "../../img/Hyundai i20 New-Gen(Automatic)/jpg/hyundai-i20-new-gen-automatic-self-drive-2.jpg"
import Hondaii20A2 from "../../img/Hyundai i20 New-Gen(Automatic)/jpg/hyundai-i20-new-gen-automatic-self-drive-3.jpg"
import Hyundaii20M from "../../img/Hyundai i20(Manual)/jpg/hyundai-i20-manual-self-drive.jpg"
import i20_old from "../../img/hyundai/i20_old.png";
// import Alcazar from "../../img/hyundai/Hyundai Alcazar.png";
import HyundaiAlcazarA from "../../img/Hyundai Alcazar ( Auto)/jpg/hyundai-alcazar-automatic-self-drive-1.jpg"
import HyundaiAlcazarA1 from "../../img/Hyundai Alcazar ( Auto)/jpg/hyundai-alcazar-automatic-self-drive-2.jpg"
import HyundaiSantroA from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-1.JPG"
import HyundaiSantroA1 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-2.JPG"
import HyundaiSantroA2 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-3.JPG"
import HyundaiSantroA3 from "../../img/Hyundai Santro New-Gen(Auto)/jpg/hyundai-santro-new-gen-self-drive-4.JPG"
 import HyundaiVenueM from "../../img/Hyundai Venue(Manual)/jpg/hyundai-venue-manual-self-drive-1.jpg"
 import HyundaiVenueM1 from "../../img/Hyundai Venue(Manual)/jpg/hyundai-venue-manual-self-drive-1.jpg"
// import Exter from "../../img/hyundai/Hyundai Exter.png";
import HyundaiExterA from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-1.jpg"
import HyundaiExterA1 from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-2.jpg"
import HyundaiExterA2 from "../../img/Hyundai Exter ( Auto)/jpg/hyundai-exter-auto-self-drive-3.jpg"
import MG from "../../img/mg/MG Hector.png";
import Stars from "../../img/Group 97.png";
import WhatsApp from "../../img/watsapp/watsapp.svg";

import transmition from "../../img/specificationIcons/Car Type.png";
import fuel from "../../img/specificationIcons/Fuel Type.png";
import mileage from "../../img/specificationIcons/Mileage.png";
import sterring from "../../img/specificationIcons/Power Steering.png";
import Engine from "../../img/specificationIcons/Engine.png";
import seater from "../../img/specificationIcons/Seater.png";

import "./style.css";
import PhoneInput from "react-phone-input-2";

const carData = [
  {
    brand: "Renault Kwid(Manual)",
    image: [Kwid, Kwid2], // Assuming you have image paths or references
    // price: 1100,
    details: {
      type: "Hatchback",
      mileage: 22,
      engine_cc: 799,
      seater: 5,
    },
  },
  {
    brand: "Renault Kwid(Auto)",
    image: [KwidA, KwidA1], // Assuming you have image paths or references
    // price: 1100,
    details: {
      type: "Hatchback",
      mileage: 22,
      engine_cc: 799,
      seater: 5,
      transmission: "Automatic",
    },
  },

  {
    brand: "Honda Brio(Manual)",
    image: [HondaBrio, HondaBrio2, HondaBrio3],
    // price: 1100,
    details: {
      type: "Hatchback",
      mileage: 18.5,
      engine_cc: 1199,
      seater: 5,
    },
  },
   // Hyundai Alcazar (Automatic)
   {
    brand: "Hyundai Alcazar (Auto)",
    image: [HyundaiAlcazarA, HyundaiAlcazarA1], // Assuming you have the image path or reference
    // price: 4000,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "18",
      engine_cc: "1482 ",
      seater: "7",
      // Add the missing details
    },
  },
  {
    brand: "Hyundai Creta New-Gen(Automatic)",
    image: [CretaNewA1, CretaNewA2], // Assuming you have the image path or reference
    // price: 4000,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "18",
      engine_cc: "1482 ",
      seater: "7",
      // Add the missing details
    },
  },
  {
    brand: "Hyundai Creta(Automatic)",
    image: [CretaA, CretaA1, CretaA2], // Assuming you have the image path or reference
    // price: 4000,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "18",
      engine_cc: "1482 ",
      seater: "7",
      // Add the missing details
    },
  },
  {
    brand: "Hyundai Creta(Manual)",
    image: [Creta, Creta1],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.7,
      engine_cc: 1497,
      seater: 5,
    },
  },
  {
    brand: "Hyundai Exter (Auto)",
    image: [HyundaiExterA, HyundaiExterA1,HyundaiExterA2], // Assuming you have the image path or reference
    // price: 2500,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "19.4",
      engine_cc: "1197",
      seater: "5",
      // Add the missing details
    },
  },
  {
    
    brand: "Hyundai i10 Grand(Manual)",
    image: [Hondai10], // Assuming you have the image path or reference
    // price: 1300,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 20.7,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "Hyundai i20 New-Gen(Manual)",
    image:[Hondaii20New, Hondaii20New1, Hondaii20New2, Hondaii20New3], // Assuming you have the image path or reference
    // price: 1600,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 20.35,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "Hyundai i20 New-Gen(Automatic)",
    image:[Hondaii20A, Hondaii20A1, Hondaii20A2], // Assuming you have the image path or reference
    // price: 1600,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 20.35,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "Hyundai i20 (Manual)",
    image:[Hyundaii20M], // Assuming you have the image path or reference
    // price: 1600,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 20.35,
      engine_cc: 1197,
      seater: 5,
    },
  },
   
  {
    brand: "Hyundai Santro New-Gen(Auto)",
    image: [HyundaiSantroA, HyundaiSantroA1, HyundaiSantroA2, HyundaiSantroA3], // Assuming you have the image path or reference
    // price: 4000,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "18",
      engine_cc: "1482 ",
      seater: "7",
      // Add the missing details
    },
  },
  {
    brand: "Hyundai Venue (Manual)",
    image:[HyundaiVenueM, HyundaiVenueM1], // Assuming you have the image path or reference
    // price: 1600,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 20.35,
      engine_cc: 1197,
      seater: 5,
    },
  },
   // Toyota Urban Cruiser (Automatic)
   {
    brand: "Innova Crysta(Automatic)",
    image: [InnovaA, InnovaA1, InnovaA2, InnovaA3], // Assuming you have the image path or reference
    // price: 4000,
    details: {
      type: "SUV",
      transmission: "Automatic",
      mileage: "18",
      engine_cc: "1482 ",
      seater: "7",
      // Add the missing details
    },
  },
  
   {
    brand: "Innova Crysta(Manual)",
    image: [InnovaM, InnovaM1, InnovaM2], // Assuming you have the image path or reference
    // price: 4500,
    details: {
      type: "SUV",
      mileage: 10.26,
      engine_cc: 2755,
      seater: 7,
      transmission: "Manual",
    },
  },
    // Innova Hycross (Automatic)
    {
      brand: "Innova Hycross(Automatic)",
      image: [ToyotaHycross], // Assuming you have the image path or reference
      // price: 4500,
      details: {
        type: "MPV",
        transmission: "Automatic",
        available: false, // Set availability status 
        seater: 7,
        mileage: "16.13",
        engine_cc: "1987",
      },
    },
  // KIA Carens (Manual)
  {
    brand: "Kia Carens(Manual)",
    image: [Carens, Carens1, Carens2, Carens3], // Assuming you have the image path or reference
    // price: 2500,
    details: {
      type: "MPV",
      transmission: "Manual",
      mileage: "17.9", // Set availability status
      seater: 6,
      engine_cc: "1493",
    },
  },
  {
    brand: "Kia Sonet(Auto)",
    image: [SonetA, SonetA1, SonetA2], // Assuming you have the image path or reference
    // price: 4500,
    details: {
      type: "MPV",
      transmission: "Automatic",
      available: false, // Set availability status 
      seater: 7,
      mileage: "16.13",
      engine_cc: "1987",
    },
  },
  {
    brand: "Kia Sonet(Manual)",
    image: [Sonet],
    // price: 3500,
    details: {
      type: "SUV",
      mileage: 18.2,
      engine_cc: 1197,
      seater: 5,
    },
  },

  {
    brand: "Mahindra Scorpio N(Manual)",
    image: [ScorpioM, ScorpioM1, ScorpioM2, ScorpioM3],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.7,
      engine_cc: 1497,
      seater: 5,
    },
  },

  {
    brand: "Mahindra Thar Old Shape(Manual)",
    image: [Thar1stG],
    // price: 4000,
    details: {
      type: "SUV",
      mileage: 14.16,
      engine_cc: 1451,
      seater: 5,
    },
  },

  {
    brand: "Mahindra Thar New-Gen(Auto)",
    image: [Thar, Thar1, Thar2],
    // price: 4000,
    details: {
      type: "SUV",
      mileage: 15.2,
      engine_cc: 1997,
      seater: "4",
      transmission: "Automatic",
    },
  },
  {
    brand: "Mahindra Thar New Gen(Manual)",
    image: [TharM, TharM1, TharM2],
    // price: 3500,
    details: {
      type: "SUV",
      mileage: 18.2,
      engine_cc: 1197,
      seater: 5,
    },
  },


  {
    brand: "Maruti Suzuki Baleno(Manual)",
    image: [Baleno, Baleno1],
    // price: 1600,
    details: {
      type: "Hatchback",
      mileage: 21.01,
      engine_cc: 1197,
      seater: 5,
      
    },
  },
  {
    brand: "Maruti Suzuki Brezza(Manual)",
    image: [MarutiBrezzaM, MarutiBrezzaM1], // Assuming you have the image path or reference
    // price: 3000,
    details: {
      type: "SUV",
      transmission: "Manual",
      mileage: 18.76,
      engine_cc: 1462,
      seater: 5,
    },
  },
  {
    brand: "Maruti Suzuki Swift Second-Gen(Manual)",
    image: [Swift, SwiftFriestG1], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "New-Gen Swift(Manual)",
    image: [SwiftSecondGen, SwiftSecondG1, SwiftSecondG2], // Assuming you have the image path or reference
    // price: 1200,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
  // Maruti New-Gen Swift (Manual)
 
  {
    brand: "New-Gen Swift(Automatic)",
    image: [Swift3rdA, Swift3rdG1A, Swift3rdG2A], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },

  {
    brand: "New-Gen Baleno(Automatic)",
    image: [BalenoA1, BalenoA2, BalenoA3],
    // price: 1600,
    details: {
      type: "Hatchback",
      mileage: 21.01,
      engine_cc: 1197,
      seater: 5,
      transmission: "Automatic",
    },
  },
  {
    brand: "New-Gen Baleno(Manual)",
    image: [NewBalenoM, NewBalenoM1, NewBalenoM2], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "New-Gen Ertiga(Automatic)",
    image: [NewErtigaA, NewErtigaA1, NewErtigaA2],
    // price: 1600,
    details: {
      type: "Hatchback",
      mileage: 21.01,
      engine_cc: 1197,
      seater: 5,
      transmission: "Automatic",
    },
  },
  {
    brand: "New-Gen Ertiga(Manual)",
    image: [NewErtigaM], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "Nexa Fronx(Auto)",
    image: [MarutiFronxA, MarutiFronxA1, MarutiFronxA2], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
  {
    brand: "Nexa Grand Vitara(Automatic)",
    image: [VitaraA, VitaraA1, VitaraA2, VitaraA3], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },
   // Nexa Igis 2023 (Manual)
   {
    brand: "Nexa Ignis(Manual)",
    image: [Ignis, Ignis1, Ignis2], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Manual",
      mileage: 20.65,
      engine_cc: 1197,
      seater: 5,
    },
  },

  {
    brand: "Nexa Jimny(Auto)",
    image: [MarutiJimnyA, MarutiJimnyA1, MarutiJimnyA2, MarutiJimnyA3], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },

  {
    brand: "Nexa XL6(Automatic)",
    image: [NexaXL6A, NexaXL6A1], // Assuming you have the image path or reference
    // price: 1400,
    details: {
      type: "Hatchback",
      transmission: "Automatic",
      mileage: 21.21,
      engine_cc: 1197,
      seater: 5,
    },
  },

  {
    brand: "Nissan Magnite(Automatic)",
    image: [Nissan, image1, image2, image3, image4,],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.7,
      engine_cc: 1497,
      seater: 5,
      transmission: "Automatic",
    },
  },

    // Fortuner new shape (Automatic)
    {
      brand: "Toyota Fortuner New-Gen(Auto)",
      image: [NewFortunerA, NewFortunerA1, NewFortunerA2, NewFortunerA3], // Assuming you have the image path or reference
      // price: 7000,
      details: {
        type: "SUV",
        mileage: 10.26,
        engine_cc: 2755,
        seater: 7,
        transmission: "Automatic",
      },
    },
    {
      brand: "Toyota Fortuner(Automatic)",
      image: [FortunerA1, FortunerA2, FortunerA3], // Assuming you have the image path or reference
      // price: 7000,
      details: {
        type: "SUV",
        mileage: 10.26,
        engine_cc: 2755,
        seater: 7,
        transmission: "Automatic",
      },
    },
  // New-Gen Glanza (Manual)
  {
    brand: "Toyota Glanza (Manual)",
    image: [ToyotaGlanza, ToyotaGlanza1, ToyotaGlanza2], // Assuming you have the image path or reference
    // price: 1600,
    details: {
      type: "Hatchback",
      mileage: 21.01,
      engine_cc: 1197,
      seater: 5,
    },
  },

  // New-Gen Glanza (Automatic)
  {
    brand: "Toyota Glanza (Auto)",
    image: [ToyotaGlanzaA, ToyotaGlanzaA1], // Assuming you have the image path or reference
    // price: 2000,
    details: {
      type: "Hatchback",
      mileage: 19.56,
      engine_cc: 1197,
      seater: 5,
      transmission: "Automatic",
    },
  },

  {
    brand: "Toyota Urban Cruiser(Auto)",
    image: [Toyota, Toyota1, Toyota2],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.03,
      engine_cc: 1462,
      seater: 5,
      transmission: "Automatic",
    },
  },

  {
    brand: "Audi A4(Automatic Transmission)",
    image: [AudiA4, AudiA41, AudiA42, AudiA43, AudiA44],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.7,
      engine_cc: 1497,
      seater: 5,
      transmission: "Automatic",
    },
  },
  {
    brand: "Audi Q3(Automatic Transmission)",
    image: [AudiQ3, AudiQ31, AudiQ32, AudiQ33],
    // price: 3000,
    details: {
      type: "SUV",
      mileage: 17.7,
      engine_cc: 1497,
      seater: 5,
      transmission: "Automatic",
    },
  },

  // Maruti Ertiga (Manual)
  // {
  //   brand: "Maruti Ertiga",
  //   image: [Ertiga], // Assuming you have the image path or reference
  //   // price: 2500,
  //   details: {
  //     type: "MPV",
  //     transmission: "Manual",
  //     mileage: 19.01,
  //     engine_cc: 1462,
  //     seater: 7,
  //   },
  // },

  // Maruti Ertiga (Automatic)
  // {
  //   brand: "Maruti Ertiga(A)",
  //   image: [Ertiga], // Assuming you have the image path or reference
  //   // price: 2800,
  //   details: {
  //     type: "MPV",
  //     transmission: "Automatic",
  //     mileage: 17.99,
  //     engine_cc: 1462,
  //     seater: 7,
  //   },
  // },

  // Maruti XL6 (Manual)
  // {
  //   brand: "Maruti XL6",
  //   image: [XL6], // Assuming you have the image path or reference
  //   // price: 2500,
  //   details: {
  //     type: "MPV",
  //     transmission: "Manual",
  //     mileage: 19.01,
  //     engine_cc: 1462,
  //     seater: 6,
  //   },
  // },

  // Maruti XL6 (Automatic)
  // {
  //   brand: "Maruti XL6(A)",
  //   image: [XL6], // Assuming you have the image path or reference
  //   // price: 3000,
  //   details: {
  //     type: "MPV",
  //     transmission: "Automatic",
  //     mileage: 17.99,
  //     engine_cc: 1462,
  //     seater: 6,
  //   },
  // },
  // {
  //   brand: "Honda City",
  //   image: [HondaCity], // Assuming you have the image path or reference
  //   // price: 2000,
  //   details: {
  //     type: "Sedan",
  //     transmission: "Automatic",
  //     mileage: 17.8,
  //     engine_cc: 1498,
  //     seater: 5,
  //   },
  // },

  // Hyundai Venue (Manual)
  // {
  //   brand: "Hyundai Venue",
  //   image: Venue, // Assuming you have the image path or reference
  //   // price: 2500,
  //   details: {
  //     type: "SUV",
  //     transmission: "Manual",
  //     mileage: 17.52,
  //     engine_cc: 1197,
  //     seater: 5,
  //   },
  // },

];

const CarList = () => {
  const { t } = useTranslation();

  

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [filteredCarData, setFilteredCarData] = useState(carData);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [noMatchingCars, setNoMatchingCars] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneNO, setPhoneNO] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);
  const [selectedCarBrand, setSelectedCarBrand] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    selectedPlace: '',
    dropOffLocation: '',
    selectedDate: null,
    timeOfPickUp: '',
    selectedDepartureDate: null,
    timeOfDropOff: '',
    selectedCarBrand: '',
  });
  const whatsappNumber = "+919850114297";

  const modelOpen = (brand) => {
    setShowModal(true);
    setSelectedCarBrand(brand);
    setFormData({
      ...formData,
      selectedCarBrand: brand,
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };
const handlePriceSelection = (priceRange) => {
  // Update the selected price ranges when a checkbox is clicked
  if (selectedPriceRanges.includes(priceRange)) {
    setSelectedPriceRanges(selectedPriceRanges.filter((item) => item !== priceRange));
  } else {
    setSelectedPriceRanges([...selectedPriceRanges, priceRange]);
  }
};

  const parsePriceRange = (range) => {
    const [min, max] = range.split('-');
    return { min: parseInt(min, 10), max: parseInt(max, 10) };
  };

const handleReset = () => {
  // Reset filters
  setSelectedBrands([]);
  setSelectedTypes([]);
  setSelectedPriceRanges([]);

  setFilteredCarData(carData);
};
  

  const handleCategorySelection = (brand) => {
    // Update the selected brands when a checkbox is clicked
    if (selectedBrands.includes(brand)) {
      setSelectedBrands(selectedBrands.filter((item) => item !== brand));
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  const handleTypeSelection = (type) => {
    // Update the selected types when a checkbox is clicked
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((item) => item !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const renderDropdownOptions = (options) => {
    return options.map((option) => (
      <Form.Check
        key={option}
        type="checkbox"
        id={`brand-checkbox-${option}`}
        label={option}
        checked={selectedBrands.includes(option)}
        onChange={() => handleCategorySelection(option)}
      />
    ));
  };
  const renderBrandDropdownOptions = (options) => {
    return options.map((option) => (
      <Form.Check
        key={option}
        type="checkbox"
        id={`brand-checkbox-${option}`}
        label={option}
        checked={selectedBrands.includes(option)}
        onChange={() => handleCategorySelection(option)}
      />
    ));
  };

  const renderCarTypeDropdownOptions = (options) => {
    return options.map((option) => (
      <Form.Check
        key={option}
        type="checkbox"
        id={`type-checkbox-${option}`}
        label={option}
        checked={selectedTypes.includes(option)}
        onChange={() => handleTypeSelection(option)}
      />
    ));
  };


  const renderPriceDropdownOptions = (options) => {
    return options.map((option) => (
      <Form.Check
        key={option}
        type="checkbox"
        id={`price-checkbox-${option}`}
        label={option}
        checked={selectedPriceRanges.includes(option)}
        onChange={() => handlePriceSelection(option)}
      />
    ));
  };

  const handleFilter = () => {
    // Apply filters based on selected brands, types, and prices
    const filteredCars = carData.filter((car) => {
      const brandMatch = selectedBrands.length === 0 || selectedBrands.includes(car.brand.split(' ')[0]);
      console.log(brandMatch);
      const typeMatch = selectedTypes.length === 0 || selectedTypes.includes(car.details.type);
      
      const priceMatch = selectedPriceRanges.length === 0 || selectedPriceRanges.some(
          (range) =>
            car.price >= parsePriceRange(range).min && car.price <= parsePriceRange(range).max
        );
  
      return brandMatch && typeMatch && priceMatch;
    });
    console.log(filteredCars);
    // Update the filtered car data
    setFilteredCarData(filteredCars);

    if (filteredCars.length === 0) {
      // Update state to indicate that there are no matching cars
      setNoMatchingCars(true);
    } else {
      // Reset the state if there are matching cars
      setNoMatchingCars(false);
    }
  };
  const handleInputChange = (e) => {
    const {  name, value } = e.target;
    
    setFormData({
      ...formData,
      [name]: value,
      
    });
  };

  const handlePhoneNo = (event) => {
    setPhoneNO();
    console.log("phone",phoneNO);
    setFormData({
      ...formData,
      phoneNumber: phoneNO,
    });
  };

  const handlePlaceSelection = (event) => {
    setSelectedPlace(event.target.value);
    setFormData({
      ...formData,
      selectedPlace: event.target.value,
    });
  };

  const handleDropLocation = (e) => {
 
    
    setFormData({
      ...formData,
     dropOffLocation: e.target.value,
      
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' represents the locale for dd/mm/yyyy format
    setFormData({
      ...formData,
      selectedDate: formattedDate,
    });
  };

  const handlePickUptime = (e) => {
 
    
    setFormData({
      ...formData,
      timeOfPickUp: e.target.value,
      
    });
  };

  const handleDepartureDateChange = (date) => {
    setSelectedDepartureDate(date);
    const formattedDate = date.toLocaleDateString('en-GB');
    setFormData({
      ...formData,
      selectedDepartureDate: formattedDate,
    });
  };

  const handleDroptime = (e) => {
 
    
    setFormData({
      ...formData,
      timeOfDropOff: e.target.value,
      
    });
  };

  const handleCarSelection = (event) => {
    console.log(event);
    const selectedValue = event.target.value;
    const selectedCarObject = carData.find(
      (car) => car.brand === selectedValue
    );
    console.log("selectedCarObject",selectedCarObject);
    setSelectedCarBrand(selectedCarObject.brand);
    setFormData({
      ...formData,
      selectedCarBrand: selectedCarObject.brand,
    });
  };

  const constructWhatsAppMessage = () => {
    // Construct the message using form data
    const message = `Hi I am interested in renting a car from Viegas car Rentals Goa and would like to request a quote.\n*Name:* ${formData.name}\n*PickUp From:* ${formData.selectedPlace}\n*PickUp Date & Time:* ${formData.selectedDate}, ${formData.timeOfPickUp}\n*Drop Off location:* ${formData.dropOffLocation}\n*Drop Off Date & Time:* ${formData.selectedDepartureDate}, ${formData.timeOfDropOff}\n*Selected Car:* ${formData.selectedCarBrand}`;
    return encodeURIComponent(message);
  };

  return (
    <section className="gauto-car-listing section_70 m-3">
      {/* <Container> */}
      <Row>
        <Col lg={3}>
          <div className="car-list-left">
           
            <div className="sidebar-widget">
              <ul className="service-menu">
                <li className="active">
                  <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Car Category
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderBrandDropdownOptions([
                          "Honda",
                          "Hyundai",
                          "KIA",
                          "Maruti",
                          "MG",
                          "Toyota"
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Car Type
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderCarTypeDropdownOptions([
                          "Sedan",
                          "SUV",
                          "Hatchback",
                          "Convertible",
                          "Truck",
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Capacity
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderDropdownOptions([
                          "2-seater",
                          "4-seater",
                          "7-seater",
                          "10-seater",
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  {/* <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Price
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderPriceDropdownOptions([
                          "1100-1500",
                          "1600-3000",
                          "3500-4000",
                          "4000-7000",
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Rating
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderDropdownOptions([
                          "1 star",
                          "2 stars",
                          "3 stars",
                          "4 stars",
                          "5 stars",
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Dropdown className="service-menu">
                    <Dropdown.Toggle
                      className="d-flex justify-content-between align-items-center"
                      id="dropdown-basic"
                    >
                      Customer Recommended
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "90%" }}>
                      <Form>
                        {renderDropdownOptions([
                          "70% and Up",
                          "60% and Up",
                          "50% and Up",
                          "40% and Up",
                        ])}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
               
              </ul>
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <button
                  style={{
                    background: "#FFDA27",
                    border: "none",
                    width: "100%",
                    padding: "5px",
                  }}
                  className="m-1"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <button
                  style={{
                    background: "",
                    border: "none",
                    width: "100%",
                    padding: "5px",
                  }}
                  className="m-1"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <div className="car-listing-right">
          <div className="car-grid-list">
      <Row>
        {filteredCarData.map((car, index) => (
          <Col  md={6} lg={6} xl={4} key={index}>
            <div className="single-offers">
      <Carousel >
         {car.image.length > 0 && (
            <Carousel.Item>
              <div className="offer-image">
                <img src={car.image[0]} alt={`offer ${index + 1}`} />
             </div>
           </Carousel.Item>
          )}
         {car.image.length > 1 && (
            <Carousel.Item>
              <div className="offer-image">
                 <img src={car.image[1]} alt={`offer ${index + 2}`} />
              </div>
           </Carousel.Item>
         )}
        {car.image.length > 2 && (
           <Carousel.Item>
              <div className="offer-image">
                <img src={car.image[2]} alt={`offer ${index + 3}`} />
             </div>
          </Carousel.Item>
        )}
           {car.image.length > 3 && (
           <Carousel.Item>
              <div className="offer-image">
                <img src={car.image[3]} alt={`offer ${index + 4}`} />
             </div>
          </Carousel.Item>
        )}
         {car.image.length > 4 && (
           <Carousel.Item>
              <div className="offer-image">
                <img src={car.image[4]} alt={`offer ${index + 5}`} />
             </div>
          </Carousel.Item>
        )}
         {car.image.length > 5 && (
           <Carousel.Item>
              <div className="offer-image">
                <img src={car.image[5]} alt={`offer ${index + 6}`} />
             </div>
          </Carousel.Item>
        )}
      </Carousel>
              
              
              <div className="offer-text">
                <div style={{ display:"flex", alignItems:"left", justifyContent:"space-between",fontSize:"19.5px",}}>
                  <Link to="">
                  <p style={{  color:"black"}}>{car.brand}</p>
                </Link>
                {/* <h4 style={{color:"#FFDA27"}}>
                  ₹{car.price}
                  <span>/ {t("day")}</span>
                </h4> */}
                </div>
                </div>
                <div style={{margin:"1%",display:"flex",alignItems:"left"}}><img src={Stars} alt="5 star ratings"/></div>
                <div className="specification">
                      <ul
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        
                        }}
                      >
                        <li >
                          <img
                            // style={{ marginRight:"10px" }}
                            src={transmition}
                            alt=""
                          />
                          {t(car.details.transmission === 'Automatic' ? 'Auto' : 'Manual')}
                        </li>
                        <li><img src={mileage} alt="fuel"/> {car.details.mileage} k</li>
                        <li><img src={fuel} alt="fuel"/>{t("P")}</li>
                      </ul>
                    </div>
                    <div className="specification">
                      <ul
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          
                        }}
                      >
                        <li><img src={sterring} alt="fuel"/>{t("Power")}</li>
                        <li><img src={Engine} alt="fuel"/> {t(`${car.details.engine_cc} cc`)}</li>
                        <li><img src={seater} alt="fuel"/>{t(`${car.details.seater}S`)}</li>
                      </ul>
                    </div>
                {/* <div className="car-details-container" style={{ margin: "5%" }}>
 
  <ul className="car-details-list" >
    <li>
      <FaCar />
      {t("Power")}
    </li>
    <li>
      <FaCogs />
      {t(`${car.details.engine_cc} cc`)}
    </li>
    <li>
      <FaTachometerAlt />
      {t(`${car.details.seater} Seater`)}
    </li>
  </ul>
</div> */}
               
               <div className="offer-btn-container">
                            <Link
                              to=""
                              onClick={() => modelOpen(car.brand)}
                              className="offer-btn-1"
                            >
                             <img className="watsapp-icon" src={WhatsApp} alt="whatsapp"/> {t("Book Now on WhatsApp")}
                            </Link>
                           
                          </div>
             
            </div>
          </Col>
        ))}
      </Row>
      <div style={{ textAlign: 'center', margin: '20px' }}>
      {noMatchingCars && <p>No matching cars found. Reset To Find a Suitable Car For you.</p>}
    </div>
    </div>

            <div className="pagination-box-row">
              <p>Page 1 of 6</p>
              <ul className="pagination">
                <li className="active">
                  <Link to="/" onClick={onClick}>
                    1
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={onClick}>
                    2
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={onClick}>
                    3
                  </Link>
                </li>
                <li>...</li>
                <li>
                  <Link to="/" onClick={onClick}>
                    6
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={onClick}>
                    <FaAngleDoubleRight />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <img width={130} height={50} src={logo} alt="Veigas Cars" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Add content for your modal here */}
            {/* <p>This is the modal content.</p> */}
          
            <Row className="align-items-center ">
              <Col md={12}>
                <div className="find-form">
                  <form onSubmit={SubmitHandler}>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input onChange={handleInputChange}  name="name" type="text" placeholder={t("Name")} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <PhoneInput
                            country={"in"} // Specify the default country using its ISO code (lowercase)
                            enableSearch // Enable search functionality for country selection
                            inputClass=" custom-phone-input" // Apply the Bootstrap form-control class for styling
                            placeholder="Enter phone number"
                            // onChange={}
                            value={phoneNO}
                            onChange={handlePhoneNo}
                            buttonStyle={{ backgroundColor: "white" }} // Set the flag image for the default country
                            inputStyle={{ borderRadius: "0px" }}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <select
                          name="place"
                            value={selectedPlace}
                            onChange={handlePlaceSelection}
                            placeholder="Select Pick Up Location"
                          >
                            <option disabled value="">
                              {t("Select Pick Up Location")}
                            </option>
                            <option value={t("Panjim")}>{t("Panjim")}</option>
                            <option value={t("Mapusa")}>{t("Mapusa")}</option>
                            <option value={t("Margao")}>{t("Margao")}</option>
                            <option value={t("Airports")}>
                              {t("Airports")}
                            </option>
                            <option value={t("Railways")}>
                              {t("Railways")}
                            </option>
                            <option value={t("North Goa")}>
                              {t("North Goa")}
                            </option>
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input
                          name="dropOffLocation"
                          onChange={handleDropLocation}
                            type="text"
                            placeholder={t("Enter Drop Off Location")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Arrival"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <p>
                            <input
                              type="text"
                              placeholder={t("Time of Pick Up")}
                              onChange={handlePickUptime}
                            />
                          </p>{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <div className="customDatePickerWidth">
                            <DatePicker
                              selected={selectedDepartureDate}
                              onChange={handleDepartureDateChange}
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              placeholderText="Date Of Departure"
                              className="custom-datepicker-input custom-datepicker-container w-100"
                            />
                          </div>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <input
                            type="text"
                            placeholder={t("Time of Drop Off")}
                            onChange={handleDroptime}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <select
                            value={selectedCarBrand}
                            onChange={handleCarSelection}
                            placeholder="Select car"
                          >
                            {carData.map((car, index) => (
                              <option key={index} value={car.brand}>
                                {car.brand}
                              </option>
                            ))}
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <div className="offer-btn-container">
                      <a
                         href={`https://wa.me/${whatsappNumber}?text=${constructWhatsAppMessage()}`}
                        className="offer-btn-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        
                      >
                        <img
                          className="watsapp-icon"
                          src={WhatsApp}
                          alt="whatsapp"
                        />{" "}
                        Book Now on WhatsApp
                      </a>
                      
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      {/* </Container> */}
    </section>
  );
};

export default CarList;
