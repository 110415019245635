import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/toyota-offer-2.png";
import img2 from "../../img/promo_bg.jpg";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                {/* <h4>{t("about_page.subtitle")}</h4> */}
                <h2>Goa's Most Trusted and Loved Self Drive Car Rental Service</h2>
                {/* <h3>{t("about_page.title")}</h3> */}
                {/* <h3>Car Rental Service</h3> */}
                <p>
                  Goa's most trusted and loved self-drive car rental service
                  offers unparalleled convenience and freedom to explore this
                  vibrant coastal destinationWhether you're seeking to
                  wander along scenic coastal roads, venture into quaint
                  villages, or immerse yourself in the energetic nightlife, our
                  service provides the perfect solution for independent travel
                  enthusiasts.
                </p>
                <p>
                  With easy booking, flexible rental options, and personalized
                  customer support, we strive to exceed expectations and make
                  every journey in Goa unforgettable. Experience the joy of
                  exploring at your own pace with Goa's premier self-drive car
                  rental service.
                </p>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:12435424"> +91 98501 14297</a>
                      <br />
                      <a href="tel:12435424">+91 88055 24237</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h2>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
    </>
  );
};

export default AboutPage;
