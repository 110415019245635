import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="container">
              {/* <h4>{t("some_words")}</h4> */}
              <div
                style={{
                  marginTop: "0.5%",
                  fontFamily: "cambria",
                  fontSize: "1rem",
                }}
              >
                Last Updated 23-July-2024
              </div>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                  gap: 10,
                }}
              >
                <h1 style={{ color: "black" }}>Viegas Car Rental's</h1>{" "}
                <h3>{t("Terms & Conditions")}</h3>
              </div>
              <ol>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>1. Timings:</strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      The rental is timed from 9 am to 9 am. Kindly note that we
                      do not follow a 24 hrs system.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    2. State Borders:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Customers are prohibited from crossing state borders with
                      the rental vehicle.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    3. Restricted Areas:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Driving on beaches and waterlogged areas is strictly
                      prohibited.Heavy penalties will be imposed if violations
                      occur.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    4. Booking Procedures:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Rentals are subject to availability and must be booked 3
                      days prior to arrival.Our minimum rental is 3 days.An
                      advance amount has to be transferred to confirm your
                      booking.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    5. Customer Identification:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Customers must provide valid identification, which will be
                      retained for security purposes.A picture of a valid
                      driver's license is required.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    6. Vehicle Inspection:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      A video recording will be made during vehicle delivery to
                      document its condition.Any damages upon return will be
                      cross-checked with this video, and charges will apply
                      accordingly to ensure transparency.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    7. Pickup and Drop-off:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Airport and railway station pickups and drop-offs are
                      available at an additional charge. Please inquire about
                      specific rates with our staff.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    8. Fuel Policy:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      The vehicle must be returned with the same fuel level as
                      provided at the start of the rental period. If the fuel
                      level is lower, a charge will be applied. If delivery and
                      drop-off locations differ, customers should confirm the
                      required fuel level with our staff.Excess fuel is
                      non-refundable.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    9. Cancellation Policy:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Bookings cannot be canceled once confirmed.Customers are
                      advised to review booking details carefully before
                      finalizing.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    10. Liability:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      The customer is liable for any fines, penalties, or
                      damages incurred during the rental period, including those
                      related to prohibited activities or breach of terms.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    11. Legal Compliance:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Customers must adhere to all local traffic and driving
                      laws throughout the rental period.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    12. Age Restrictions:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Drivers must meet the minimum age requirement as per local
                      regulations and possess a valid driver's license.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    13. Maintenance:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Customers are responsible for maintaining the vehicle in
                      good condition during the rental period, including regular
                      checks of fluid levels and tire pressure.
                    </p>
                  </ul>
                </li>
                <li>
                  <strong style={{ fontFamily: "cambria" }}>
                    14. Dispute Resolution:
                  </strong>
                  <ul>
                    <p
                      style={{
                        fontFamily: "cambria",
                        fontSize: "18px",
                        lineHeight: "1.5rem",
                        textAlign: "justify",
                      }}
                    >
                      Any disputes arising from these terms and conditions will
                      be resolved in accordance with local laws and regulations.
                    </p>
                  </ul>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TermsAndConditions;
